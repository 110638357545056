/* IconGrid.module.css */
.grid {
	display: grid;
	grid-template-columns: repeat(5, 1fr); 
	grid-template-rows: repeat(3, 1fr); 
	gap: 0.6rem; 
	justify-items: center;
	align-items: center;
	justify-content: center;
	align-content: center;
	width: fit-content; 
	margin: 2%; 
	margin-top: 10%;
  }
  
  .iconWrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
  }
  
