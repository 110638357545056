/* Base styles for desktop */
.container {
  background-image: url("/background.png") !important;
  background-size: cover;
  background-repeat: no-repeat !important;
  background-attachment: fixed !important;
  width: 100% !important;
  min-height: 90vh !important;
  display: flex !important;
  flex-direction: column !important;
  color: white;
}



.centerContent {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.grayBackground {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 2rem;
  height: 10rem;
  padding-top: 1rem;
}

.positionUserProfile {
  padding: 0.05rem;
  padding-bottom: 12rem !important;
}

.menuIcon {
  text-align: left;
  padding: 10px;
  background-color: #ed1c24;
}

.headerText {
  text-align: left;
  padding: 10px;
  font-size: 1.1rem;
  color: white;
}

.gameTeaser {
  padding: 9rem;
  font-size: 1.1rem;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: flex-end;
  width: 100%;
  margin-inline-end: 0px;
}

.prize {
  font-size: 1.5rem;
  margin-top: 1rem;
}

.positionPlayButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 1rem;

  width: 100%;
}

.positionPlayButton {
  display: flex;
  justify-content: center;
  padding-right: 5%;
  margin-top: 2rem; /* Ajustez selon vos besoins */
  margin-left: 2rem;
}

.positionPlayButton > * {
  margin: 0 1rem; /* Ajoute une marge horizontale entre les boutons */
}

.playButton, .cancelButton {
  padding-left: 4rem;
  padding-right: 4rem;
  padding-top: 1.2rem;
}




/* Media Queries for Smartphones */
@media (max-width: 600px) {
  .container {
    padding: 0.5em;
  }

  .centerContent {
    flex-direction: column;
    align-items: center;
  }

  .grayBackground {
    padding: 0.5rem;
  }

  .headerText {
    font-size: 1rem;
    padding: 0.5rem;
  }

  .gameTeaser {
    padding: 2rem;
    font-size: 0.9rem;
  }

  .prize {
    font-size: 1.2rem;
  }

  .positionPlayButton {
    flex-direction: row;
    padding-bottom: 0.5rem;
    padding-right: 0;
    padding-left: 0;
  }

  .positionPlayButton > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .playButton, .cancelButton {
    width: 90% !important;
    margin: 0.2rem !important;
    padding-top: 0.5rem;
  }
}
