.balanceContainer {
	display: flex !important;
	flex: 1 !important;
	align-items: flex-start !important;
	justify-content: center !important;
	margin: 0.5em 0 !important;
	width: 100% !important;
	
}

.container {
	background-image: url("/background.png") !important;
	background-size: cover !important;
	width: 100% !important;
	min-height: 100vh !important;
	flex-direction: column !important;
}



.buttonGroup {
	display: flex !important;
	flex-direction: row !important;
	flex: 1 !important;
	justify-content: space-between !important;
	margin: 0.2em !important;
	padding: 0.1em !important;
}

/* OperationScreen.module.css */
.buttonStyle {
	height: 1% !important;
	margin: 0.2em !important;
	padding: 0.1em;
  }
  

.inputsContainer {
	display: flex !important;
	flex-direction: column !important;
	flex: 3 !important;
	justify-content: center !important;
	margin: 0.5em !important;
	padding: .5em !important;
	color: #ffffff;
}

.logo {
	width: 50% !important;
	object-fit: scale-down !important;
}

.logoContainer {
	display: flex !important;
	flex: 1 !important;
	align-items: center !important;
	justify-content: center !important;
	margin: 3em 0.5em 0.5em 0.5em !important;
}

.divider {
	width: 100% !important;
	margin: 1.5rem 0 !important;
	background-color: white;
}

.playButton {
	background-color: #F99500;
	color: white;
	border-radius: 10px;
	height: 44px;
	width: 174px;
  }
  .positionPlayButton {
	align-items: center;
	padding-bottom: 5rem;
	align-self: center;
  }

  .cancelButton {
	background-color: #F99500;
	color: white;
	border-radius: 10px;
	height: 44px;
	width: 174px;
  }
  .cancelPosition {
	align-items: center;
	padding-bottom: 5rem;
	align-self: center;
  }
  .menuIcon {
    background-color: #ED1C24; 
    width: 100%;
    padding: 10px;
    text-align: left;
    bottom: 90%;
  }
  
  .userPhone {
	color: white;
	font-size: 1rem;
	text-align: center;
	margin: 10px 0;
  }
  .balanceDisplay {
	color: white;
	font-size: 1rem;
	text-align: center;
	margin: 10px 0;
  }

  .dropdownContainer {
	display: flex !important;
	justify-content: center !important;
	margin: 20px !important;
  }
  
  .dropdownStyle {
	width: 70% !important;
	border: 1px solid #ccc !important;
	border-radius: 3% !important;
	padding: 2% !important;
	box-shadow: 0 2px 5px rgba(0,0,0,0.1) !important;
  }

  /* OperationScreen.module.css */
.inputContainer {
	display: flex;        /* Use flexbox to center the content */
	justify-content: center; /* Center horizontally */
	align-items: center;  /* Center vertically if needed */
	margin: 20px;         /* Margin around the div */
	padding: 10px;        /* Padding inside the div */
  }
  
  
  