
.congratulationsContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 10;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  
  .congratulationsText {
    color: green;
    font-size: 2rem;
    animation: scaleUp 0.5s ease-in-out;
  }
  
  .congratulationsMessage {
    color: #333;
    font-size: 1.5rem;
  }
  
  @keyframes scaleUp {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }
  