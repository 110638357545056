

.languageList {
    width: 100%;
    max-width: 400px; 
    margin: auto;
    scrollbar-width: auto;
  }
  
  .languageItem {
    border-radius: 20px;
    margin: 8px 0;
    padding: 12px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.2em;
  }
  
  .languageItem:hover {
    background-color: #f0f0f0;
  }
  
  .languageItem.active {
    border: 2px solid #2185d0;
  }
  
  .checkIcon {
    color: green;
  }
  