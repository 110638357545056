.input {
	font-family: 'RobotoCondensed';
	font-size: 1em;
	color: white;
	background: transparent;
	border: 0;
	width: 100%;
  }
  
  .inputContainer {
	display: flex;
	align-items: center;
	border-bottom: 1px solid;
	border-radius: 0px;
	
  }
  .input, .passwordInput {
		font-family: 'RobotoCondensed';
		font-size: 1em;
		color: white; /* Text color */
		background: transparent;
		border: 0;
		width: 100%;
	  }
	  
	  .inputContainer, .passwordInputContainer {
		display: flex;
		align-items: center;
		border-bottom: 1px solid;
		border-radius: 0px;
		background: transparent;
		margin: 0.5em;
		padding: 0.5em;
	  }
	  
	  .input::placeholder, .passwordInput::placeholder { 
		color: white; /* Placeholder text color */
		opacity: 1;
	  }
	  
	  /* For Internet Explorer 10-11 */
	  .input:-ms-input-placeholder, .passwordInput:-ms-input-placeholder { 
		color: white;
	  }
	  
	  /* For Microsoft Edge */
	  .input::-ms-input-placeholder, .passwordInput::-ms-input-placeholder { 
		color: white;
	  
	  
	background: transparent;
	margin: 0.5em !important;
	padding: 0.5em !important;
  }
  
  .input::placeholder, .passwordInput::placeholder { 
	color: white;
	opacity: 1;
  }
  
  .input:-ms-input-placeholder, .passwordInput:-ms-input-placeholder { 
	color: yellow;
  }
  
  .input::-ms-input-placeholder, .passwordInput::-ms-input-placeholder { 
	color: white;
  }
  