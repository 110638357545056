/* Base styles for desktop */
.container {
  background-image: url("/background.png") !important;
  background-size: cover !important;
  width: 100% !important;
  min-height: 100vh !important;
  display: flex !important;
  flex-direction: column !important;
  color: white;
}

.balanceContainer {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin: 0.5em 0 !important;
  width: 100% !important;
}

.inputsContainer {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  margin: 0.5em !important;
  padding: 0.5em !important;
}

.logoContainer {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin: 3em 0.5em !important;
}

.logo {
  width: 50% !important;
  object-fit: contain !important;
}

.divider {
  width: 100% !important;
  margin: 1.5rem 0 !important;
  background-color: white;
}

.button,
.playButton,
.cancelButton {
  background-color: #f99500;
  color: white;
  border-radius: 10px;
  height: 44px;
  width: 174px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1em 0;
  cursor: pointer;
}

.positionPlayButton,
.cancelPosition {
  align-items: center;
  padding-bottom: 5rem;
  align-self: center;
}

.menuIcon {
  background-color: #ed1c24;
  padding: 10px;
  text-align: left;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.button:hover,
.playButton:hover,
.cancelButton:hover {
  background-color: darken(#f99500, 10%);
}

.button:active,
.playButton:active,
.cancelButton:active {
  background-color: darken(#f99500, 20%);
}

.cancelButton {
  background-color: #f99500;
  color: white;
  border-radius: 10px;
  height: 44px;
  width: 174px;
}

.cancelPosition {
  align-items: center;
  padding-bottom: 5rem;
  align-self: center;
}

.menuIcon {
  background-color: #ed1c24;
  width: 100%;
  padding: 10px;
  text-align: left;
  bottom: 90%;
}

.buttonGroup {
  display: flex;
  justify-content: space-around; /* Adjust as needed for your layout */
  margin-bottom: 10px;
}

.activeButton {
  background-color: green;
  color: white;
}

.inactiveButton {
  background-color: grey;
  color: white;
}

.inputField {
  color: white;
}

/* Media Queries for Smartphones */
@media (max-width: 480px) {
  .logo {
    width: 70% !important;
  }

  .button,
  .playButton,
  .cancelButton {
    width: 90% !important;
  }

  .inputsContainer,
  .balanceContainer {
    width: 90% !important;
    margin: 0.2em !important;
    padding: 0.2em !important;
  }

  .divider {
    margin: 1em 0 !important;
  }
}
