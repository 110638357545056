/* RulesModal.module.css */

.modalOpen {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modalClosed {
    display: none;
  }
  
  .modalContent {
    background: red;
    padding: 20%;
    border-radius: 10px;
    max-height: 80%;
    overflow: hidden; 
  }
  
  .scrollText {
    height: 100%;
    overflow: hidden;
    animation: scrollUp 10s linear infinite; 
    font-size: large;
  }
  
  @keyframes scrollUp {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(-100%);
    }
  }
  