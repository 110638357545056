.container {
    width: 100%;
    height: 100vh;
    background-image: url("/background.png"); /* Ensure path is correct */
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 20px;
}

.content {
    width: 80%;
    max-width: 500px;
    padding: 30px;
    background-color: rgba(172, 13, 13, 0.9); /* White overlay */
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.titleText {
    font-family: "RobotoCondensed", sans-serif;
    font-size: 1.8em;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
    color: white;
}

.titleTextLabel {
    color: white;
}

.buttonStyle {
    width: 100%;
    background-color: red;
    font-size: 1.2em;
    padding: 12px 0;
    color: red;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s ease;
}

.buttonStyle:hover {
    background-color: red;
}

.errorMessage {
    color: red;
    font-size: 0.9em;
    margin-top: 5px;
}

/* Responsive adjustments */
@media (max-width: 600px) {
    .content {
        width: 100%;
        padding: 20px;
    }

    .titleText {
        font-size: 1.4em;
    }

    .buttonStyle {
        font-size: 1em;
        padding: 10px;
    }
}
