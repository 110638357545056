/* Menu.module.css */

.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 1000;
  display: flex;
  justify-content: left;
  align-items: flex-start;
  padding-top: 11%;
}

.menuIcon {
  cursor: pointer;
  z-index: 1010;
  position: relative;
}


.menuItem {
	cursor: pointer;
	color: white;
	font-size: 1.9rem;
	display: flex;
	align-items: center;
	justify-content: flex-start; 
	width: 100%;
	padding: 10px; 
	margin-bottom: 1px; 
	background-color:red;
  }
  
  .menuItem:hover {
	background-color: rgba(255, 255, 255, 0.1);
  }
  
  .menuItemText {
	margin-left: 0.5px; 
  }
