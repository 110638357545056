/* Icon.module.css */
.iconWrapper {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
  }
  
  .icons {
	width: 3.5rem; /* Adjust as needed */
	height: 3rem; /* Adjust as needed */
	border-radius: 50%; /* Rounded icons */
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.2rem; /* Adjust font size for numbers */
	background-color:red;
	/*border: 1rem solid red; /* Red border */
	box-sizing: border-box;
  }
  .winning {
	background-color: green; 
	animation: pulseAnimation 1s infinite;
	border: 2px solid green; /* Red border */
  }
  .winningLogo {
	background-color: yellow; 
	animation: pulseAnimation 1s infinite;
	border: 2px solid yellow; /* green border */

  }
  .bonus {
	background-color: green; /* Green background for bonus icons */
  }
  
  
  .iconLabel {
	position: absolute;
	text-align: center;
	width: 100%; /* Make label width as wide as icon */
	top: 50%; /* Center vertically */
	left: 50%; /* Center horizontally */
	transform: translate(-50%, -50%); /* Adjust to perfect center */
	display: flex;
	flex-direction: column; /* Stack words vertically */
  }
  
  .iconLabelWord {
	line-height: 1; 
	font-size: 0.2rem; 
	color:#ED1C24; 
	font-weight: bold;
  }

@keyframes fadeIn {
	from { opacity: 0; }
	to { opacity: 1; }
  }
  
  .fadeIn {
	animation-name: fadeIn;
	animation-duration: 0.5s;
	animation-fill-mode: both;
  }
  
  
  .selected {
	background-color: white; /* Red background for selected icon */
	border-radius: 10%; 
  }
  
  /* Add animation for winning icon */
  .winning {
	animation: pulseAnimation 1s infinite;
	
  }
  
  @keyframes pulseAnimation {
	0% { transform: scale(1); }
	50% { transform: scale(1.1); }
	100% { transform: scale(1); }
  }



  
  

  