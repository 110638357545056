
body {
  margin: 0;
  padding: 0;
  background-color: red; 
  overflow-y: auto;
}

.tutorielScreen {
  font-family: Arial, sans-serif;
  background-color: red;
  color: white; 
  height: 100vh; 
  width: 100vw; 
}

.header {
  text-align: center;
  padding: 20px;
}

.gameOverview, .howToPlay, .winningCriteria, .prizes, .accountManagement {
  padding: 15px;
  margin: 10px;
  border-bottom: 1px solid white; 
}

.footer {
  padding: 10px;
  text-align: center;
}
