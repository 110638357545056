.tableContainer {
    width: 60% !important;
    margin: 0 auto;
}

.text {
    font-family: "RobotoCondensed" !important;
    font-size: 1em !important;
}

.titleText {
    font-family: "RobotoCondensed" !important;
    font-size: 1em !important;
    font-weight: bold;
}

.pagination {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    width: 100% !important;
}

.smallPagination {
    height: 5em !important;
    display: flex !important;
    flex-direction: column;
    justify-content: space-between !important;
    align-items: flex-start !important;
    width: 100% !important;
}

.itemCount {
    font-family: "RobotoCondensed" !important;
    font-size: 1em !important;
    font-weight: bold !important;
    margin-left: 0.5em !important;
}

@media (max-width: 600px) {
    .pagination {
        flex-direction: column !important;
        align-items: flex-start !important;
    }

    .itemCount {
        margin-top: 0.5em !important;
        margin-left: 0 !important;
    }

    .tableContainer {
        width: 100% !important;
    }
}
