.winnersContainer {
    padding: 20px;
  }
  
  .header {
    margin-bottom: 20px;
  }
  
  .userDropdown {
    margin-bottom: 20px;
  }
  
  .userInput {
    width: 100%;
    padding: 10px;
    font-size: 16px;
  }
  