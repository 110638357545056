/* General styles */
.container {
  background-image: url("/background.png") !important;
  background-size: cover !important;
  width: 100% !important;
  min-height: 100vh !important;
  display: flex !important;
  flex-direction: column !important;
  color: white;
}

.grayBackground {
  background-color: gray;
  padding: 2rem;
  height: 10rem;
  padding-top: 1rem;
}

.positionUserProfile {
  padding: 0.05rem;
  padding-bottom: 12rem !important;
}

.menuIcon {
  text-align: left;
  padding: 10px;
  background-color: #ed1c24;
}

.headerText {
  text-align: left;
  padding: 10px;
  font-size: 1.1rem;
}

/* .gameTeaser {
  padding: 9rem;
  font-size: 1.1rem;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: flex-end;
  width: 100%;
  margin-inline-end: 0px;
} */

.gameTeaser {
  padding: 9rem;
  font-size: 1.1rem;
  display: flex;
  flex-direction: column;
  align-items: center; /* Corrected from 'left' */
  justify-content: flex-end;
  width: 100%;
  margin-inline-end: 0px;

  /* Prevent text breaking */
  white-space: nowrap; /* Ensures the text does not break */
  overflow: hidden; /* Hides any overflowing content */
  text-overflow: ellipsis; /* Adds an ellipsis if text overflows */
}



.prize {
  font-size: 2.5rem;
  margin-top: 1rem;
}

.saveButtonPosition {
  position: absolute;
  padding-right: 5%;
  bottom: 20%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.saveButton {
  background-color: #f99500;
  color: white;
  border-radius: 10px;
  height: 44px;
  width: 174px;
}

/* Extra small devices (phones, 600px and down) */
@media (max-width: 600px) {
  .container {
    padding: 0.5em;
  }

  .grayBackground {
    padding: 0.5rem;
  }

  .headerText {
    font-size: 1rem;
    padding: 0.5rem;
  }

  .gameTeaser {
    padding: 9rem;
    font-size: 0.9rem;
  }

  .prize {
    font-size: 1.2rem;
  }

  .saveButtonPosition {
    bottom: 10%;
  }

  .saveButton {
    width: 90%;
  }

  .menuIcon {
    padding: 5px;
  }

  .positionUserProfile {
    padding-bottom: 10rem !important;
  }
}
