/* SettingsScreen.module.css */

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: gray; 
  overflow-y: auto; /* Makes the screen scrollable */
}

.menuIcon {
  position: fixed; /* Fix the position relative to the viewport */
  top: 0; /* Align to the top of the viewport */
  background-color: red;
  width: 100%;
  padding: 10px 0; /* Adjust the padding as needed */
  z-index: 1000; /* Ensure it stays on top of other content */
}


.saveButtonPosition {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

