.container {
	width: 100% !important;
	height: 100% !important;
}

.menuContainer {
	overflow: hidden !important;
}

.menu {
	z-index: 5 !important;
	height: 100% !important;
	width: 100% !important;
	min-height: 100vh !important;
}

.contentContainer {
	background-image: url("/background.png") !important;
	background-size: cover !important;
	width: 100% !important;
	min-height: 100vh !important;
	height: 100% !important;
	flex-wrap: wrap !important;
}

.content {
	height: 100% !important;
	width: 100% !important;
	padding: 1em !important;
	display: flex !important;
	flex-direction: column !important;
	align-items: center !important;
}
.menuIcon {
	text-align: left; /* Align the icon to the left */
	padding: 10px; /* Add some padding around the icon */
	background-color: #ED1C24;
  }