.dashboardContainer {
    width: 100%;
    min-height: 100vh;
    background-image: url("/background.png"); /* Ensure path is correct */
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
}

.header {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    color: #333;
    background-color: rgba(255, 255, 255, 0.7);
    padding: 10px;
    border-radius: 8px;
}

.monthDropdown {
    margin-bottom: 2rem;
    width: 50%;
    max-width: 400px;
}

.ui.card {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2) !important;
    padding: 1rem;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 8px;
}

.ui.statistic .value {
    font-size: 2.5rem !important;
    color: #2185d0;
}

.ui.statistic .label {
    font-size: 1.2rem !important;
    color: #555;
    text-align: right;
}

@media (max-width: 768px) {
    .header {
        font-size: 1.5rem;
    }

    .monthDropdown {
        width: 80%;
    }

    .ui.statistic .value {
        font-size: 2rem !important;
    }

    .ui.statistic .label {
        font-size: 1rem !important;
    }
}
