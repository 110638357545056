.profileContainer {
	display: flex;
	flex-direction: column; /* Stack elements vertically */
	background-color: #fff;
	padding: 20px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	align-items: center; /* Align children to the center horizontally */
  }
  
  .leftSection {
	display: flex;
	align-items: center; /* Align avatar and name/phone container */
  }
  
  .avatar {
	width: 80px;
	height: 80px;
	border-radius: 50%;
	object-fit: cover;
	margin-right: 20px;
  }
  
  .namePhoneContainer {
	text-align: left;
  }
  
  .name {
	margin: 0;
	color: #333;
	font-size: 1.5rem;
  }
  
  .phoneNumber {
	margin: 0;
	color: #666;
	font-size: 1rem;
  }
  
  .divider {
	width: 100%; /* Full-width divider */
	height: 1px;
	background-color: #ddd;
	margin: 10px 0;
  }

  .balanceBonusContainer {
	width: 100%; /* Ensure this container takes full width */
	display: flex;
	justify-content: space-around; /* This will space out balance and bonus evenly */
	align-items: center;
	position: relative; /* For absolute positioning of the divider */
  }
  
  .balanceBonusContainer::after {
	content: '';
	position: absolute;
	right: 50%; /* Position at the exact middle */
	top: 0;
	bottom: 0;
	width: 1px; /* The thickness of the divider */
	background-color: #ddd; /* The color of the divider */
	transform: translateX(50%); /* Center the divider */
  }
  
  .balance, .bonus {
	text-align: center;
	padding: 0 20px; /* Add some padding around balance and bonus for spacing */
  }
  
  
  .amount {
	font-size: 1.5rem;
	color: #333;
	display: block;
  }
  
  .label {
	font-size: 1rem;
	color: #666;
	display: block;
  }
  